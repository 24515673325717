import React, {useEffect, useMemo, useState} from "react";
import {Button, Card, Checkbox, Flex, Group, Loader, Stack, Switch, Table, Text, Title, Tooltip} from "@mantine/core";
import classes from "./common.module.css";
import {useQuery} from "@tanstack/react-query";
import {ErrorBox} from "../error-box";
import {Member} from "../../models/member";
import useMantineMediaQueries from "../../hooks/useMantineMediaQueries";
import {useAuth0} from "@auth0/auth0-react";
import SEPA from "sepa";
import {SILA_INFO} from "../../utils/sila-bank-info";
import xmlFormat from 'xml-formatter';
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {DateInput} from "@mantine/dates";

dayjs.extend(customParseFormat);

enum ViewType {
    Active,
    All,
    Error
}

function generateUUID() {
    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0,
            v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

const getSepa = async (token: string) => {
    const response = await fetch(`${process.env.REACT_APP_API_SERVER_URL}/api/members`, {
        headers: {
            Authorization: `Bearer ${token}`, // Attach the Auth0 token to the request
        },
    });

    if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
    }

    return await response.json();
};

export const Sepa: React.FC = () => {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [collectionDate, setCollectionDate] = useState<Date | null>(null);
    const [viewType, setViewType] = useState(ViewType.Active);
    const { data, refetch, isLoading, error } = useQuery<Member[]>({
        queryKey: ["members"],
        queryFn: async () => {
            // Get the access token from Auth0
            const token = await getAccessTokenSilently();
            return getSepa(token);
        },
        enabled: isAuthenticated,
    });
    const [members, setMembers] = useState<(Member & { disable?: boolean })[]  | undefined>(data?.map(member => ({...member, disable: false})));

    const isValidSEPA = (member: Member) => SEPA.validateIBAN(member.bankAccount.trim()) && member.sepaAllowDate && member.sepaReference && member.bic && member.sepaAllowDate?.length > 1 && member.amount > 0;
    const SEPAErrorType = (member: Member) => ({
            name: !member.name,
            iban: !SEPA.validateIBAN(member.bankAccount.trim()),
            date: !member.sepaAllowDate || member.sepaAllowDate?.length < 2,
            bic: !member.bic,
            amount: member.amount <= 0,
            mandateId: !member.sepaReference,
    });
    const isSEPA = (member: Member) => member.type === 'SEPA' || (member.type === "Letno SEPA" && dayjs().format('mm') === '01');

    const membersToProcess = members?.filter((member) => isSEPA(member) && isValidSEPA(member)) || [];
    const membersToShow = useMemo(() => {
        if (viewType === ViewType.Error) {
            return members?.filter(member => isSEPA(member) && !isValidSEPA(member));
        } else if (viewType === ViewType.Active) {
            return membersToProcess;
        }
        return members;
    }, [viewType, members])

    useEffect(() => {
        if (data) {
            setMembers(data.map(member => ({...member, disable: false})));
        }
    }, [data]);

    const total = membersToShow?.reduce((acc, member) => acc + parseFloat(String(member?.amount ?? 0)), 0);
    const formattedTotal =  new Intl.NumberFormat('si-SL', { style: 'currency', currency: 'EUR' }).format(total ?? 0);
    const {isMobile} = useMantineMediaQueries({withInitialValues: true});

    const reorderPurpBeforeRmtInf = (xmlString: string) => {
        // Parse the XML string using DOMParser
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlString, 'application/xml');

        // Get all <DrctDbtTxInf> elements
        const directDebitTxInfoElements = xmlDoc.getElementsByTagName('DrctDbtTxInf');

        // Loop through each <DrctDbtTxInf> element
        for (let i = 0; i < directDebitTxInfoElements.length; i++) {
            const directDebitTxInfo = directDebitTxInfoElements[i];

            // Find the <Purp> and <RmtInf> elements inside the current <DrctDbtTxInf>
            const purpElement = directDebitTxInfo.getElementsByTagName('Purp')[0];
            const rmtInfElement = directDebitTxInfo.getElementsByTagName('RmtInf')[0];
            // Check if both <Purp> and <RmtInf> exist and if <Purp> comes after <RmtInf>
            if (purpElement && rmtInfElement && purpElement.previousElementSibling === rmtInfElement) {
                // Move <Purp> to before <RmtInf>
                directDebitTxInfo.insertBefore(purpElement, rmtInfElement);
            }
        }

        // Serialize the XML back to a string
        const serializer = new XMLSerializer();
        return serializer.serializeToString(xmlDoc);
    }

    const generateSepa = async () => {
        if (!data) return;
        const doc = new SEPA.Document('pain.008.001.02');
        doc.grpHdr.id = generateUUID().substring(0, 28);
        doc.grpHdr.created = new Date();
        doc.grpHdr.initiatorName = SILA_INFO.receiverName;
        doc.grpHdr.initiatorId = SILA_INFO.receiverId;
        doc.grpHdr.initiatorStreet = SILA_INFO.receiverAddress;
        doc.grpHdr.initiatorCity = SILA_INFO.receiverPost;
        doc.grpHdr.initiatorCountry = "SI";
        doc.grpHdr.batchBooking = false;

        // Add each member as a transaction in the SEPA transfer
        membersToProcess.filter(member => isSEPA(member) && !member.disable).forEach((member: Member) => {
            const info = doc.createPaymentInfo();
            info.collectionDate = dayjs(collectionDate).add(2, 'hour').toDate();
            info.creditorIBAN = SILA_INFO.receiverIban;
            info.creditorBIC = SILA_INFO.receiverBic;
            info.creditorName = SILA_INFO.receiverName;
            info.creditorId = SILA_INFO.receiverId;
            info.creditorStreet = SILA_INFO.receiverAddress;
            info.creditorCity = SILA_INFO.receiverPost;
            info.creditorCountry = "SI";
            info.sequenceType = 'RCUR'
            doc.addPaymentInfo(info);

            const tx = info.createTransaction();
            tx.debtorName = member.name;
            tx.debtorIBAN = member.bankAccount.trim();
            tx.debtorBIC = member.bic;
            tx.debtorCity = member.post;
            tx.debtorStreet = member.address;
            tx.debtorCountry = "SI";
            tx.mandateId = member.sepaReference;
            tx.mandateSignatureDate = dayjs(member.sepaAllowDate?.trim(), 'D. M. YYYY').toDate();
            tx.amount = member.amount;
            tx.currency = 'EUR'; //optional
            tx.remittanceInfo = "Članarina SZD SILA";
            tx.purposeCode = 'OTHR';
            tx.end2endId = `SI00 ${member.id}-${dayjs().format('MM-YYYY')}`;
            // tx.end2endId = crypto.randomUUID().replace(/-/g, "");
            info.addTransaction(tx);
        });

        const reorderedXmlString = reorderPurpBeforeRmtInf(doc.toString());
        // Generate XML as a string
        const xmlContent = xmlFormat(reorderedXmlString, {
            indentation: '  ',
            filter: (node) => node.type !== 'Comment',
            collapseContent: true,
            lineSeparator: '\n'
        })

        // Create a Blob from the XML content
        const blob = new Blob([xmlContent], { type: "application/xml" });
        const url = URL.createObjectURL(blob);

        // Create a link element, set the href to the blob URL, and click it to download
        const a = document.createElement("a");
        a.href = url;
        a.download = `sepa-transfer-${dayjs().format('DD-MM-YYYY')}.xml`;
        a.click();

        // Clean up the URL object after download
        URL.revokeObjectURL(url);
    };

    const rows = membersToShow?.map((member) => {
        const errors = isSEPA(member) && !isValidSEPA(member) ? SEPAErrorType(member) : null;

        return (
        <Table.Tr key={member.id}>
            <Table.Td>
                <Checkbox
                    checked={member.disable}
                    onChange={(event) => {
                        const memberIndex = members!.findIndex(m => m.id === member.id);
                        const newMembers = [...members!];
                        newMembers[memberIndex] = {...member, disable: event.currentTarget.checked};
                        setMembers(newMembers);
                    }}
                />
            </Table.Td>
            <Table.Td>{member.type}</Table.Td>
            <Table.Td>{member.id}</Table.Td>
            <Table.Td style={{background: errors?.name ? '#ffbdbd' : undefined}}>{member.name}</Table.Td>
            <Table.Td style={{background: errors?.amount ? '#ffbdbd' : undefined}}>{member.amount}€</Table.Td>
            <Table.Td style={{background: errors?.iban ? '#ffbdbd' : undefined}}>{member.bankAccount}</Table.Td>
            <Table.Td style={{background: errors?.bic ? '#ffbdbd' : undefined}}>{member.bic}</Table.Td>
            <Table.Td style={{background: errors?.date ? '#ffbdbd' : undefined}}>{member.sepaAllowDate}</Table.Td>
            <Table.Td style={{background: errors?.mandateId ? '#ffbdbd' : undefined}}>{member.sepaReference}</Table.Td>
        </Table.Tr>
    )}
    );

    return (
        <Card shadow="sm" padding="lg" radius="md" className={classes.card}>
            <Stack>
                <DateInput
                    value={collectionDate}
                    onChange={setCollectionDate}
                    label="Datum izvedbe SEPA transakcij"
                    placeholder="Vnesi datum"
                    valueFormat="D MMM YYYY"
                    minDate={new Date()}
                />
                <Flex justify="space-between" align="center">
                    <Title>SEPA</Title>
                    <Group gap="xs">
                        <Tooltip label={`Za generiranje morata biti nastavljena datum in "Aktivni ta mesec"`} disabled={!!collectionDate && viewType === ViewType.Active}>
                        <Button onClick={generateSepa} disabled={!collectionDate || viewType !== ViewType.Active}>Generiraj .xml</Button>
                        </Tooltip>
                        <Button variant="outline" onClick={() => refetch()}>Osveži</Button>
                    </Group>
                </Flex>
                <Group
                    p={"xs"}
                    align="center"
                    wrap="nowrap"
                >
                    <Switch
                        styles={{
                            description: {
                                fontSize: 16,
                                marginTop: 0,
                            }
                        }}
                        description="Aktivni ta mesec"
                        checked={viewType === ViewType.Active}
                        onChange={(event) => setViewType(event.currentTarget.checked ? ViewType.Active : ViewType.All)}
                    />
                    <Switch
                        styles={{
                            description: {
                                fontSize: 16,
                                marginTop: 0,
                            }
                        }}
                        description="Napačni podatki"
                        checked={viewType === ViewType.Error}
                        onChange={(event) => setViewType(event.currentTarget.checked ? ViewType.Error : ViewType.All)}
                    />
                </Group>
                <Stack>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <Table.ScrollContainer minWidth={isMobile ? undefined : 800}>
                            <Table highlightOnHover>
                                <Table.Thead>
                                    <Table.Tr>
                                        <Table.Th>Ne trgaj</Table.Th>
                                        <Table.Th>Tip</Table.Th>
                                        <Table.Th>ID</Table.Th>
                                        <Table.Th>Ime</Table.Th>
                                        <Table.Th>Članarina</Table.Th>
                                        <Table.Th>IBAN</Table.Th>
                                        <Table.Th>BIC</Table.Th>
                                        <Table.Th>SEPA datum</Table.Th>
                                        <Table.Th>SEPA referenca</Table.Th>
                                    </Table.Tr>
                                </Table.Thead>
                                <Table.Tbody>{rows}</Table.Tbody>
                            </Table>
                        </Table.ScrollContainer>
                    )}
                </Stack>
                <ErrorBox error={error} loading={isLoading} />
                <Text fw={500}>Skupna vsota: {formattedTotal}</Text>
            </Stack>
        </Card>
    );
};
