import React, { useState } from "react";
import {
    Button,
    Card,
    Table,
    Title,
    Stack,
    FileInput,
    Text,
    Modal,
    Group,
    Blockquote, Flex,
} from "@mantine/core";
import { useMutation } from "@tanstack/react-query";
import { XMLParser } from "fast-xml-parser";
import { Member } from "../../models/member";
import { IconCheck } from "@tabler/icons-react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

interface SendXmlResponse {
    message: string;
}

interface ParsedDocument {
    Document: {
        CstmrPmtStsRpt: {
            GrpHdr?: {
                CreDtTm?: string;
            };
            OrgnlPmtInfAndSts: ParsedTransaction[];
        };
    };
}

interface ParsedTransaction {
    StsRsnInf?: {
        Rsn?: {
            Cd?: string;
        };
    };
    TxInfAndSts: {
        OrgnlTxRef: OriginalTransactionReference;
    };
}

interface OriginalTransactionReference {
    Amt: {
        InstdAmt: {
            "#text": string;
            "@_Ccy": string;
        };
    };
    ReqdColltnDt: string;
    CdtrSchmeId: {
        Id: {
            PrvtId: {
                Othr: {
                    Id: string;
                    SchmeNm: {
                        Prtry: string;
                    };
                };
            };
        };
    };
    MndtRltdInf?: {
        DtOfSgntr: string;
        MndtId: string;
    };
    Dbtr: Debtor;
    DbtrAcct: {
        Id: {
            IBAN: string;
        };
    };
    DbtrAgt: {
        FinInstnId: {
            BIC: string;
        };
    };
}

interface Debtor {
    Nm: string;
    PstlAdr: {
        Ctry: string;
        AdrLine: string | string[];
    };
    Id: {
        PrvtId: {
            Othr: {
                Id: string;
                SchmeNm: {
                    Prtry: string;
                };
            };
        };
    };
}

const sendXmlData = async (xmlData: Member[]): Promise<SendXmlResponse> => {
    const response = await fetch(
        `${process.env.REACT_APP_API_SERVER_URL}/api/sepa-denied/upload`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ xmlData }),
        }
    );
    if (!response.ok) {
        throw new Error("Failed to send XML data to the backend");
    }
    return response.json();
};

const parseXmlToMembers = (xmlContent: string): Member[] => {
    const parser = new XMLParser({
        ignoreAttributes: false,
        attributeNamePrefix: "@_",
    });

    const jsonData: ParsedDocument = parser.parse(xmlContent);
    const transactions = jsonData?.Document?.CstmrPmtStsRpt?.OrgnlPmtInfAndSts || [];
    const rejectionDate = transactions?.[0]?.TxInfAndSts?.OrgnlTxRef?.ReqdColltnDt || jsonData.Document?.CstmrPmtStsRpt?.GrpHdr?.CreDtTm;

    return transactions.map((transaction: ParsedTransaction): Member => {
        const txInfo = transaction.TxInfAndSts.OrgnlTxRef;
        const debtor = txInfo.Dbtr;
        const rejectionCode = transaction.StsRsnInf?.Rsn?.Cd || "";
        const debtorAddress = Array.isArray(debtor.PstlAdr.AdrLine)
            ? debtor.PstlAdr.AdrLine.join(", ")
            : debtor.PstlAdr.AdrLine || "";

        const addressMatch = debtorAddress.match(/(.+),\s*(\d{4}\s+.+)/);
        const address = addressMatch ? addressMatch[1] : debtorAddress;
        const post = addressMatch ? addressMatch[2] : "";
        const id = parseInt(txInfo.MndtRltdInf?.MndtId?.slice(1) || debtor?.Id?.PrvtId?.Othr?.Id || "0", 10) || 0;

        return {
            id,
            name: debtor.Nm || "Unknown",
            email: "", // Placeholder as email is not present in the XML
            phone: "", // Placeholder as phone is not present in the XML
            address,
            post,
            bankAccount: txInfo.DbtrAcct.Id.IBAN || "",
            bankName: "", // Placeholder as bank name is not present in the XML
            bic: txInfo.DbtrAgt.FinInstnId.BIC || "",
            amount: parseFloat(
                txInfo.Amt.InstdAmt["@_Ccy"] === "EUR" ? txInfo.Amt.InstdAmt["#text"] : "0"
            ),
            sepaReference: txInfo.CdtrSchmeId.Id.PrvtId.Othr.Id || "",
            sepaAllowDate: txInfo.ReqdColltnDt || "",
            type: "SEPA", // Default type; modify if needed
            rejectionDate,
            rejectionCode,
        };
    });
};

export const SepaDenied: React.FC = () => {
    const [members, setMembers] = useState<Member[]>([]);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const mutation = useMutation<SendXmlResponse, Error, Member[]>({
        mutationFn: sendXmlData,
    });
    const [date, setDate] = useState<string>();

    const handleFileUpload = (file: File | null): void => {
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (event) => {
            const xmlContent = event.target?.result as string;
            const parsedMembers = parseXmlToMembers(xmlContent);
            setDate(parsedMembers?.[0]?.sepaAllowDate);
            setMembers(parsedMembers);
        };
        reader.readAsText(file);
    };

    const handleSendData = (): void => {
        if (members.length > 0) {
            mutation.mutate(members);
            setModalOpen(false);
        }
    };

    const totalLoss = members.reduce((sum, member) => sum + member.amount, 0);
    const formattedTotalLoss = new Intl.NumberFormat('si-SI', { style: 'currency', currency: 'EUR' }).format(totalLoss);

    return (
        <Card shadow="sm" padding="lg" radius="md">
            <Stack>
                <Flex justify="space-between">
                    <Title>Sepa Denied</Title>
                    {date && <Text>
                        {`Month: ${dayjs(date, 'YYYY-MM-DD').format('MMMM')}`}
                    </Text>}
                </Flex>
                <FileInput
                    placeholder="Upload .xml file"
                    accept=".xml"
                    onChange={handleFileUpload}
                />
                {members.length > 0 && (
                    <>
                        <Table highlightOnHover>
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Address</th>
                                <th>Post</th>
                                <th>Bank Account</th>
                                <th>BIC</th>
                                <th>Amount</th>
                            </tr>
                            </thead>
                            <tbody>
                            {members.map((member) => (
                                <tr key={member.id}>
                                    <td>{member.id}</td>
                                    <td>{member.name}</td>
                                    <td>{member.address}</td>
                                    <td>{member.post}</td>
                                    <td>{member.bankAccount}</td>
                                    <td>{member.bic}</td>
                                    <td>{new Intl.NumberFormat('si-SI', { style: 'currency', currency: 'EUR' }).format(member.amount)}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                        <Text mt="md" fw={500}>Izguba: {formattedTotalLoss}</Text>
                        {mutation.isSuccess && (
                            <Blockquote icon={<IconCheck />} p="md">
                                Uspešno poslano!
                            </Blockquote>
                        )}
                        <Button onClick={() => setModalOpen(true)} loading={mutation.isPending}>
                            Naloži podatke
                        </Button>
                    </>
                )}
                {/* Modal for confirmation */}
                <Modal
                    opened={isModalOpen}
                    onClose={() => setModalOpen(false)}
                    title="Potrditev pošiljanja"
                    centered
                >
                    <Text>Ste prepričani, da želeti naložiti podatke v tabelo</Text>
                    <Group mt="md">
                        <Button variant="default" onClick={() => setModalOpen(false)}>Prekini</Button>
                        <Button onClick={handleSendData} loading={mutation.isPending}>Potrdi</Button>
                    </Group>
                </Modal>
            </Stack>
        </Card>
    );
};
